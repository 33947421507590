import { Box, Divider, Typography } from "@mui/material";
import BasicTable from "./BasicTable";
import PaymentChart from "./PaymentChart";
import { Piechart } from "./Piechart";

export default function GraphWidget({ paymentData, loading }) {
	return (
		<Box>
			<Box sx={{ display: "flex", mt: 3 }}>
				<Box
					sx={{
						flex: 1,
						minWidth: "500px",
						height: "424px",
						borderRadius: "16px",
						border: "1px solid rgba(238, 238, 238, 1)",
						mr: 3,
					}}
				>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontSize: "18px",
							fontWeight: 600,
							p: 3,
						}}
					>
						Metrix
					</Typography>
					<Divider />
					<PaymentChart paymentData={paymentData} />
				</Box>
				<Box
					sx={{
						flex: 1,
						minWidth: "500px",
						height: "424px",
						borderRadius: "16px",
						border: "1px solid rgba(238, 238, 238, 1)",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontSize: "18px",
							fontWeight: 600,
							p: 3,
						}}
					>
						Graph
					</Typography>
					<Divider />
					<Piechart />
				</Box>
			</Box>
			<Box
				sx={{
					flex: 1,
					minWidth: "500px",
					height: "424px",
					borderRadius: "16px",
					border: "1px solid rgba(238, 238, 238, 1)",
					mt: 3,
				}}
			>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontSize: "18px",
						fontWeight: 600,
						p: 3,
					}}
				>
					Transaction History
				</Typography>
				<Divider />
				<BasicTable paymentData={paymentData} loading={loading} />
			</Box>
		</Box>
	);
}
