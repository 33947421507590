import { Box, Dialog, Skeleton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Drawer } from "../../components/Drawer";
import NaveBar from "../../components/NaveBar";
import { BlueButton } from "../../components/BlueButton";
import { PrimaryGrey, primaryColor } from "../../constant";
import { createWebhook, deleteWebhook, getWebhooks } from "../../api/webhooks";
import { MdContentCopy, MdDelete } from "react-icons/md";
import { shortText } from "../../utils/shortText";
import { InputFieldComponent } from "../../components/InputField";
import { toast } from "react-toastify";

export const Webhooks = () => {
	const [webhooks, setWebhooks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [createOpen, setCreateOpen] = useState(false);

	async function gW() {
		setLoading(true);
		const response = await getWebhooks();
		setWebhooks(response);
		setLoading(false);
	}

	async function handleCreateClose() {
		setCreateOpen(false);
	}

	useEffect(() => {
		gW();
	}, []);

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer />
			<Box style={{ width: `calc(100vw - 280px)` }}>
				<NaveBar />
				<Box style={{ width: `calc(100vw - 280px)` }}>
					<Box
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							color: "#4c4848",
						}}
					>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<h1>Webhooks</h1>
							<br />
							<Dialog
								open={createOpen}
								onClose={handleCreateClose}
								fullWidth
								maxWidth="xs"
							>
								<CreateWebhookDialog
									closeDialog={() => {
										handleCreateClose();
										gW();
									}}
								/>
							</Dialog>
							<BlueButton
								title={"Create webhook"}
								style={{ backgroundColor: primaryColor }}
								onClick={() => setCreateOpen(true)}
							/>
						</Box>
						{loading ? (
							<Box>
								{Array.from({ length: 10 }).map((_, i) => (
									<Skeleton
										variant="rectangular"
										sx={{ my: 1 }}
										height={"75px"}
										key={i}
									/>
								))}
							</Box>
						) : (
							<Box mt={2}>
								<table>
									<thead>
										<tr>
											<th>Title</th>
											<th>Secret</th>
											<th>Delete</th>
											<th>Created</th>
										</tr>
									</thead>
									<tbody>
										{webhooks.map((item, i) => (
											<WebhookTile webhook={item} getWebhooks={gW} key={i} />
										))}
									</tbody>
								</table>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export const CreateWebhookDialog = ({ closeDialog }) => {
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [url, setUrl] = useState("");

	async function cW() {
		try {
			if (loading) return;
			setLoading(true);
			if (!title || title === "") throw Error("Enter webhook title.");
			if (!url || url === "" || !isValidHttpUrl(url))
				throw Error("Enter a valid webhook url.");
			await createWebhook(title, url);
			closeDialog();
			setLoading(false);
		} catch (error) {
			toast(error.message, { type: "error" });
			setLoading(false);
		}
	}

	function isValidHttpUrl(string) {
		let url;
		try {
			url = new URL(string);
		} catch (_) {
			return false;
		}

		return url.protocol === "http:" || url.protocol === "https:";
	}

	return (
		<Box
			sx={{
				padding: "32px",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<p style={{ fontSize: "24px", fontWeight: "500" }}>Create Webhook</p>
			<br />
			<InputFieldComponent
				title={"Title"}
				placeholder={"Webhook title"}
				onChange={(e) => setTitle(e.target.value)}
				value={title}
			/>
			<br />
			<InputFieldComponent
				title={"Webhook URL"}
				placeholder={"Webhook URL"}
				onChange={(e) => setUrl(e.target.value)}
				value={url}
			/>
			<br />
			<BlueButton
				style={{
					backgroundColor: primaryColor,
				}}
				onClick={cW}
				title="Create"
				loading={loading}
			/>
		</Box>
	);
};

const WebhookTile = ({ webhook, getWebhooks }) => {
	const [copyEnabled, setCopyEnabled] = useState(false);
	const [open, setOpen] = useState(false);

	function toggleCopy() {
		setCopyEnabled(!copyEnabled);
	}

	async function dW(id) {
		if (window.confirm("Delete webhook?")) {
			await deleteWebhook(id);
			getWebhooks();
		}
	}

	return (
		<tr>
			<td>{webhook.title}</td>
			<td
				style={{
					fontWeight: "500",
					color: "#303031",
					display: "flex",
					cursor: "pointer",
				}}
				onMouseEnter={toggleCopy}
				onMouseLeave={toggleCopy}
			>
				<p>{shortText(webhook.secret)}</p>
				<Tooltip
					title="Copied!"
					placement="top"
					open={open}
					onClose={() => setOpen(false)}
				>
					<Box
						onClick={() => {
							navigator.clipboard.writeText(webhook.secret);
							setOpen(true);
						}}
					>
						<MdContentCopy
							style={{
								color: PrimaryGrey,
								visibility: copyEnabled ? "visible" : "hidden",
								cursor: "pointer",
							}}
							size={16}
						/>
					</Box>
				</Tooltip>
			</td>
			<td>
				<MdDelete
					style={{
						color: PrimaryGrey,
						cursor: "pointer",
					}}
					size={16}
					onClick={() => dW(webhook._id)}
				/>
			</td>
			<td>{new Date(webhook.createdAt).toDateString()}</td>
		</tr>
	);
};
