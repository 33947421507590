import "../styles/Payment.css";
import {
	AppBar,
	Box,
	CircularProgress,
	Divider,
	Grid,
	Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { primaryBlack } from "../constant";
import { getNetworks } from "../api/network";
import { BlueButton } from "../components/BlueButton";
import QRCode from "react-qr-code";
import { MdArrowBack, MdContentCopy } from "react-icons/md";
import { SiDiscord, SiTwitter } from "react-icons/si";
import { BiLogoTelegram } from "react-icons/bi";
import Countdown from "react-countdown";
import { cancelPayment, createPayment, getPayment } from "../api/payment";
import { useParams } from "react-router-dom";
import {
	connectWalletToSite,
	getWalletAddress,
	switchChain,
} from "../utils/wallet";
import Web3 from "web3";
import ERC20 from "../contracts/ERC20.json";
import CompletedGif from "../assets/completed.gif";
import { getPaymentLink } from "../api/payment_link";
import { toast } from "react-toastify";
import Logo from "../assets/logo.png";
import LogoDark from "../assets/logo-dark.png";

export const Payment = () => {
	const { id } = useParams();
	const [paymentLink, setPaymentLink] = useState({});
	const [loading, setLoading] = useState(false);
	const [networks, setNetworks] = useState([]);
	const [tokens, setTokens] = useState([]);
	const [network, setNetwork] = useState({});
	const [token, setToken] = useState({});
	const [open, setOpen] = useState(false);
	const [priceOpen, setPriceOpen] = useState(false);
	const [payment, setPayment] = useState({});
	const timer = useRef();

	function handleSuccess() {
		window.close();
	}

	async function handleBack() {
		await cancelPayment(payment.uid);
		clearInterval(timer.current);
		setPayment({});
	}

	function handleNetworkChange(network) {
		setTokens(network.tokens);
		setNetwork(network);
		setToken({});
	}

	function handleTokenChange(token) {
		setToken(token);
	}

	async function cP() {
		try {
			if (
				loading ||
				Object.keys(network).length === 0 ||
				Object.keys(token).length === 0
			)
				return;
			setLoading(true);
			const response = await createPayment(id, token._id, network.chain_id);
			if (!response) {
				setLoading(false);
				return toast("Unable to create payment, please try again later", {
					type: "info",
				});
			}
			setPayment(response);
			checkStatus(response);
			setLoading(false);
		} catch (error) {
			console.log(error.message);
			setLoading(false);
		}
	}

	async function checkStatus(bill) {
		if (!bill) return;
		timer.current = setInterval(async () => {
			const response = await getPayment(bill.uid);
			if (response.status === "CANCELED" || response.status === "PENDING") {
				setPayment(response);
				clearInterval(timer.current);
			}
		}, 10000);
	}

	async function payUsingWebWallet() {
		try {
			const token = payment.token;
			await connectWalletToSite();
			await switchChain(token.chain_id);
			const web3 = new Web3(window.web3.currentProvider);
			const currentAddress = await getWalletAddress();
			const unit = getUnit(token.decimals);
			const value = toWei(payment.amount, unit);

			if (token.native) {
				web3.eth
					.sendTransaction({
						from: currentAddress,
						to: payment.payment_address,
						value,
					})
					.then(async (r) => {
						console.log(r);
					})
					.catch((e) => {
						console.log("errored", e);
					});
			} else {
				const contract = new web3.eth.Contract(ERC20.abi, token.token_address);

				// Gas Calculation
				const gasPrice = await web3.eth.getGasPrice();
				const gas = await contract.methods
					.transfer(payment.payment_address, value)
					.estimateGas({
						from: currentAddress,
					});

				contract.methods
					.transfer(payment.payment_address, value)
					.send({
						from: currentAddress,
						gasPrice,
						gas,
					})
					.on("transactionHash", function (hash) {
						console.log(hash);
					})
					.on("receipt", async function (receipt) {
						console.log(receipt);
					})
					.on("error", (e) => {
						console.log(e);
					});
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getNetworks().then((response) => {
			setNetworks(response);
			setNetwork(response[0]);
			setTokens(response[0].tokens);
		});
		getPaymentLink(id).then(setPaymentLink);
	}, [id]);

	return (
		<Box
			sx={{
				height: "100vh",
				width: "100vw",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box>
				<Navbar paymentLink={paymentLink} payment={payment} />
			</Box>
			<Box
				sx={{
					flex: 1,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minWidth: "30vw",
				}}
			>
				<Box sx={{ display: "flex" }}>
					{/* Left Component */}
					{Object.keys(payment).length === 0 ? (
						<Box className="container">
							{/* Company & profuct info */}
							<CompanyInfo paymentLink={paymentLink} />
							<Box sx={{ flex: 1, m: 1, p: 3 }}>
								<Box
									sx={{
										flex: 3,
									}}
								>
									<p
										style={{
											fontWeight: "600",
											fontSize: "18px",
										}}
									>
										Select Payment Method
									</p>
									<small
										style={{
											fontWeight: "500",
										}}
									>
										Select prefered network and token to pay in.
									</small>
									{/* Select Network */}
									<Box>
										<p
											style={{
												fontWeight: "600",
												margin: "16px 0",
											}}
										>
											1. Choose network
										</p>
										<Grid
											container
											spacing={{ xs: 1, sm: 1, md: 1 }}
											columns={{ xs: 1, sm: 12 }}
										>
											{networks.map((n, i) => {
												return (
													<Grid item xs={12} sm={6} md={4} key={i}>
														<Box
															sx={{
																border:
																	network._id === n._id
																		? `2px solid ${primaryBlack}`
																		: "1px solid #f3f3f3",
																borderRadius: "6px",
																p: 1.5,
																height: "100%",
																width: "100%",
																display: "flex",
																alignItems: "center",
																"&:hover": {
																	cursor: "pointer",
																	background: "#f3f3f3",
																},
															}}
															onClick={() => handleNetworkChange(n)}
														>
															<Box
																sx={{
																	backgroundImage: `url("${n.logo}")`,
																	backgroundRepeat: "no-repeat",
																	backgroundPosition: "center",
																	backgroundSize: "cover",
																	minHeight: "25px",
																	minWidth: "25px",
																	mr: 0.5,
																}}
															></Box>
															<Box>{n.name}</Box>
														</Box>
													</Grid>
												);
											})}
										</Grid>
									</Box>
									{/* Select Token */}
									<Box>
										<p
											style={{
												fontWeight: "600",
												margin: "16px 0",
											}}
										>
											2. Choose token
										</p>
										<Grid
											container
											spacing={{ xs: 1, sm: 1, md: 1 }}
											columns={{ xs: 1, sm: 12 }}
										>
											{tokens.map((t, i) => {
												return (
													<Grid item={true} xs={12} sm={6} md={4} key={i}>
														<Box
															sx={{
																border:
																	token._id === t._id
																		? `2px solid ${primaryBlack}`
																		: "1px solid #f3f3f3",
																borderRadius: "6px",
																p: 1.5,
																height: "100%",
																width: "100%",
																display: "flex",
																alignItems: "center",
																"&:hover": {
																	cursor: "pointer",
																	background: "#f3f3f3",
																},
															}}
															onClick={() => handleTokenChange(t)}
														>
															<Box
																sx={{
																	backgroundImage: `url("${t.logo}")`,
																	backgroundRepeat: "no-repeat",
																	backgroundPosition: "center",
																	backgroundSize: "cover",
																	minHeight: "25px",
																	minWidth: "25px",
																	mr: 0.5,
																}}
															></Box>
															<Box>{t.token_name}</Box>
														</Box>
													</Grid>
												);
											})}
										</Grid>
									</Box>
								</Box>
								<BlueButton
									title={"Continue"}
									style={{
										mt: 4,
										backgroundColor: primaryBlack,
										cursor:
											Object.keys(network).length === 0 ||
											Object.keys(token).length === 0
												? "no-drop"
												: "pointer",
									}}
									onClick={cP}
									loading={loading}
								/>
							</Box>
						</Box>
					) : (
						""
					)}
					{/* Right Component */}
					{Object.keys(payment).length !== 0 && payment.status === "CREATED" ? (
						<Box className="container">
							<CompanyInfo paymentLink={paymentLink} />
							<Box
								sx={{
									borderLeft: { xs: "none", md: "1px solid #f3f3f3" },
								}}
							>
								{/* Timer */}
								<Timer
									payment={payment}
									sx={{ display: { xs: "none", md: "flex" } }}
								/>
								<Box
									sx={{
										flex: 2,
										p: 2,
										pt: 1,
										m: 0.5,
										textAlign: "center",
									}}
								>
									<Box sx={{ textAlign: "start" }}>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												textDecoration: "underline",
												mb: 1,
												cursor: "pointer",
											}}
											onClick={handleBack}
										>
											<MdArrowBack />
											<small
												style={{
													fontWeight: "600",
												}}
											>
												Back
											</small>
										</Box>
										<small
											style={{
												fontWeight: "500",
											}}
										>
											Pay with
										</small>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												mt: 0.5,
												mb: 2,
											}}
										>
											<Box
												sx={{
													backgroundImage: `url("${token.logo}")`,
													backgroundRepeat: "no-repeat",
													backgroundPosition: "center",
													backgroundSize: "cover",
													minHeight: "25px",
													minWidth: "25px",
													mr: 0.5,
												}}
											></Box>
											<p
												style={{
													fontWeight: "600",
													fontSize: "14px",
												}}
											>
												{token.token_name}({network.name})
											</p>
										</Box>
									</Box>
									<Tooltip
										title="Copied!"
										placement="top"
										open={priceOpen}
										onClose={() => setPriceOpen(false)}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												cursor: "pointer",
											}}
											onClick={() => {
												navigator.clipboard.writeText(payment.amount);
												setPriceOpen(true);
											}}
										>
											<h2>{payment.amount}</h2>
											<MdContentCopy
												style={{
													marginLeft: "4px",
												}}
											/>
										</Box>
									</Tooltip>
									<small style={{ fontWeight: "600" }}>
										1 {token.token_name} = ${payment.price}
									</small>
									{/* OR CODE */}
									<Box my={2}>
										<QRCode value={payment.payment_address} size={150} />
									</Box>
									<Box>
										<Box
											sx={{ display: "flex", justifyContent: "space-between" }}
										>
											<small style={{ fontWeight: "600" }}>
												Recipient {token.token_name} address
											</small>
											<Tooltip
												title="Copied!"
												placement="top"
												open={open}
												onClose={() => setOpen(false)}
											>
												<Box
													onClick={() => {
														navigator.clipboard.writeText(
															payment.payment_address
														);
														setOpen(true);
													}}
												>
													<MdContentCopy
														style={{
															cursor: "pointer",
														}}
													/>
												</Box>
											</Tooltip>
										</Box>
										<Box
											sx={{
												backgroundColor: "lightgrey",
												borderRadius: "6px",
												p: 1,
												fontWeight: "600",
												fontSize: "12px",
											}}
										>
											<p>{payment.payment_address}</p>
										</Box>
									</Box>
									{/* Divider */}
									<Box sx={{ display: "flex", alignItems: "center", my: 1.5 }}>
										<Divider sx={{ flex: 1 }} />
										<Box sx={{ fontWeight: "600", fontSize: "12px", mx: 2 }}>
											OR
										</Box>
										<Divider sx={{ flex: 1 }} />
									</Box>
									<BlueButton
										title={"Pay With Web Wallet"}
										style={{
											backgroundColor: primaryBlack,
											cursor:
												Object.keys(network).length === 0 ||
												Object.keys(token).length === 0
													? "no-drop"
													: "pointer",
										}}
										onClick={payUsingWebWallet}
									/>
									{/* UID */}
									<Box sx={{ mt: 2, fontWeight: "600", fontSize: "14px" }}>
										<small>UID: {payment.uid}</small>
									</Box>
								</Box>
								<Timer
									payment={payment}
									sx={{ display: { xs: "flex", md: "none" } }}
								/>
							</Box>
						</Box>
					) : (
						""
					)}
					{/* Success Component */}
					{Object.keys(payment).length !== 0 && payment.status === "PENDING" ? (
						<Box
							sx={{
								border: "1px solid #f3f3f3",
								borderRadius: "6px",
								p: 2,
							}}
						>
							<h2>Payment Completed</h2>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img src={CompletedGif} height={"100px"} alt="completed" />
							</Box>
							<BlueButton
								title={"Continue"}
								style={{
									mt: 2,
									backgroundColor: primaryBlack,
									cursor: "pointer",
								}}
								onClick={handleSuccess}
							/>
						</Box>
					) : (
						""
					)}
				</Box>
			</Box>
			{/* Footer */}
			<Box
				sx={{
					backgroundColor: primaryBlack,
					width: "100vw",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					p: 2,
					px: 4,
					color: "white",
				}}
			>
				<Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							mb: 1,
						}}
					>
						<Box
							sx={{
								width: "36px",
								height: "36px",
								mr: 1.5,
							}}
						>
							<img src={Logo} alt="" height="100%" width="100%" />
						</Box>
						<Box
							sx={{
								fontSize: "24px",
								fontWeight: "600",
								color: "white",
							}}
						>
							Ziropay
						</Box>
					</Box>
					<small style={{ fontSize: "12px", fontWeight: "500" }}>
						Experience limitless transactions with a crypto payment gateway
						<br />
						that keeps on giving.
					</small>
				</Box>
				<Box>
					<SiDiscord
						style={{ margin: "0 8px", cursor: "pointer" }}
						onClick={() => window.open("https://x.com/leostelon", "_blank")}
					/>
					<BiLogoTelegram
						style={{ margin: "0 8px", cursor: "pointer" }}
						onClick={() => window.open("https://x.com/leostelon", "_blank")}
					/>
					<SiTwitter
						style={{ margin: "0 8px", cursor: "pointer" }}
						onClick={() => window.open("https://x.com/leostelon", "_blank")}
					/>
				</Box>
			</Box>
		</Box>
	);
};

const CompanyInfo = ({ paymentLink }) => {
	return (
		<>
			{Object.keys(paymentLink).length !== 0 ? (
				<Box
					sx={{
						m: 1,
						mr: 4,
						p: 3,
						flex: 1,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box flex={1}>
						<Box
							sx={{
								mb: 1.5,
								display: "flex",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									mr: "6px",
								}}
							>
								<img
									src={paymentLink.user?.logo ?? LogoDark}
									alt="comp-logo"
									height="36px"
									width="36px"
								/>
							</Box>
						</Box>
						<p
							style={{
								fontWeight: "600",
								fontSize: "18px",
							}}
						>
							{paymentLink.payment_config.title}
						</p>
						<small
							style={{
								fontWeight: "500",
							}}
						>
							{paymentLink.payment_config.description}
						</small>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							fontWeight: "500",
						}}
					>
						<h4 style={{ marginBottom: 4 }}>{paymentLink.user?.name}</h4>
						<small style={{ marginBottom: 2 }}>
							{paymentLink.user?.website}
						</small>
						<small style={{ marginBottom: 2 }}>{paymentLink.user?.email}</small>
						<small style={{ marginBottom: 2 }}>{paymentLink.user?.phone}</small>
						<small style={{ marginBottom: 2 }}>
							{paymentLink.user?.address}
						</small>
					</Box>
				</Box>
			) : (
				""
			)}
		</>
	);
};

const Navbar = ({ paymentLink, payment }) => {
	const [loading, setLoading] = useState(false);

	async function cP() {
		try {
			setLoading(true);
			if (payment && Object.keys(payment).length !== 0) {
				await cancelPayment(payment.uid);
			}
			if (paymentLink && Object.keys(paymentLink).length !== 0) {
				const redirect_url = paymentLink.payment_config.redirect_url;
				if (redirect_url && redirect_url !== "")
					window.location.replace(redirect_url);
				else window.close();
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error.message);
		}
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position="static"
				sx={{
					bgcolor: primaryBlack,
					boxShadow: "none",
				}}
			>
				<Box
					sx={{
						height: "60px",
						p: 3,
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								width: "36px",
								height: "36px",
								mr: 1.5,
							}}
						>
							<img src={Logo} alt="" height="100%" width="100%" />
						</Box>
						<Box
							sx={{
								fontSize: "24px",
								fontWeight: "600",
								color: "white",
							}}
						>
							Ziropay
						</Box>
					</Box>
					<Box
						sx={{
							borderRadius: "6px",
							border: "1px solid white",
						}}
					>
						<BlueButton
							title={"Cancel"}
							style={{
								backgroundColor: primaryBlack,
								cursor: "pointer",
							}}
							onClick={cP}
							loading={loading}
						/>
					</Box>
				</Box>
			</AppBar>
		</Box>
	);
};

function Timer({ payment, sx }) {
	return (
		<Box
			sx={{
				backgroundColor: primaryBlack,
				borderTopRightRadius: "6px",
				color: "white",
				p: 2,
				alignItems: "center",
				justifyContent: "space-between",
				// display: { xs: "none", md: "none", lg: "none" },
				...sx,
			}}
			className="timer"
		>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<CircularProgress
					size={14}
					sx={{ color: "white", mr: 1 }}
					thickness={6}
				/>
				<small>
					{payment.status === "CREATED"
						? "Awaiting payment"
						: "Payment recieved"}
				</small>
			</Box>
			<Countdown date={payment.expire_at}>
				<small>Expired!</small>
			</Countdown>
		</Box>
	);
}

function toWei(number, unit) {
	return Web3.utils.toWei(number, unit);
}

function getUnit(decimals) {
	switch (decimals) {
		case 18:
			return "ether";
		case 6:
			return "picoether";
		default:
			return "ether";
	}
}
