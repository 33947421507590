import { default as axios } from "axios";
import { SERVER_URL } from "../constant";
import { toast } from "react-toastify";

export const signin = async function (email) {
	try {
		const response = await axios.post(
			`${SERVER_URL}/user/signin`,
			{ email },
			{
				method: "POST",
			}
		);
		return response.status;
	} catch (error) {
		console.log(error.message);
	}
};

export const signinGoogle = async function (code) {
	try {
		const response = await axios.post(
			`${SERVER_URL}/user/signin/google`,
			{ code },
			{
				method: "POST",
			}
		);
		if (response.status === 200) {
			const data = response.data;
			localStorage.setItem("token", data.token);
			window.location.replace("/");
		} else {
			toast("Google sigin failed", { type: "error" });
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const getUser = async function () {
	try {
		const token = localStorage.getItem("token");
		const response = await axios.get(`${SERVER_URL}/user`, {
			headers: {
				Authorization: `Bearer ` + token,
			},
		});
		return response;
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return error.response;
		}
	}
};
