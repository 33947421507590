import { default as axios } from "axios";
import { SERVER_URL } from "../constant";
import { authResolver } from "../utils/authResolver";
import { toast } from "react-toastify";

export const createPayment = async function (amount, payment_config) {
	try {
		let a = parseFloat(amount);
		if (typeof a !== "number") return alert("Invalid amount type");
		const token = localStorage.getItem("token");

		const response = await axios.post(
			SERVER_URL + "/payment_link",
			{ amount, payment_config },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response.status === 200) {
			toast("Created payment", { type: "success" });
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const getPayments = async function () {
	try {
		const token = localStorage.getItem("token");
		const response = await authResolver(
			axios.get(SERVER_URL + `/payment_link`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
		);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const getPaymentLink = async function (id) {
	try {
		const token = localStorage.getItem("token");
		const response = await authResolver(
			axios.get(SERVER_URL + `/payment_link/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
		);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};
