import { default as axios } from "axios";
import { SERVER_URL } from "../constant";
import { toast } from "react-toastify";

export const getWebhooks = async function () {
	try {
		const token = localStorage.getItem("token");
		const response = await axios.get(`${SERVER_URL}/webhook`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const createWebhook = async function (title, webhook_url) {
	const token = localStorage.getItem("token");
	try {
		const response = await axios.post(
			`${SERVER_URL}/webhook`,
			{ title, webhook_url },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response.status === 200) {
			toast("Added webhook successfully", { type: "success" });
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const deleteWebhook = async function (id) {
	const token = localStorage.getItem("token");
	try {
		const response = await axios.delete(`${SERVER_URL}/webhook/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.status === 200) {
			toast("Webhook deleted successfully.", { type: "success" });
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};
