import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ReactComponent as Category } from "../svg/Category.svg";
import { ReactComponent as CategoryDark } from "../svg/Category-dark.svg";
import { ReactComponent as Chart } from "../svg/Chart.svg";
import { ReactComponent as ChartDark } from "../svg/Chart-dark.svg";
import { ReactComponent as Document } from "../svg/Document.svg";
import { ReactComponent as DocumentDark } from "../svg/Document-dark.svg";
import { ReactComponent as SettingGear } from "../svg/Setting_Gear.svg";
import { ReactComponent as SettingGearDark } from "../svg/Setting_Gear-dark.svg";
import { ReactComponent as Code } from "../svg/Code.svg";
import { ReactComponent as CodeDark } from "../svg/Code-dark.svg";
import { SiDiscord, SiTwitter } from "react-icons/si";
import { BiLogoTelegram } from "react-icons/bi";
import Logo from "../assets/logo.png";
import LogoDark from "../assets/logo-dark.png";
import BGImage from "../assets/card-background.jpg";

const mainList = [
	{
		text: "Dashboard",
		i: () => <Category />,
		ai: () => <CategoryDark />,
		path: "/",
	},
	{
		text: "Payment Links",
		i: () => <Chart />,
		ai: () => <ChartDark />,
		path: "/payment_links",
	},
	{
		text: "Payments",
		i: () => <Document />,
		ai: () => <DocumentDark />,
		path: "/payments",
	},
	{
		text: "Create Payment",
		i: () => <Document />,
		ai: () => <DocumentDark />,
		path: "/create",
	},
	{
		text: "Settings",
		i: () => <SettingGear />,
		ai: () => <SettingGearDark />,
		path: "/settings",
	},
	{
		text: "Developer",
		i: () => <Code />,
		ai: () => <CodeDark />,
		path: "/developer",
	},
];

const drawerWidth = 260;
const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const LeftDrawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export function Drawer({ smaller }) {
	const location = useLocation();
	const [index, setIndex] = useState(0);
	const navigate = useNavigate();
	const open = true;

	function updateIndex(path) {
		switch (path) {
			case "/":
				return setIndex(0);
			case "/payment_links":
				return setIndex(1);
			case "/payments":
				return setIndex(2);
			case "/create":
				return setIndex(3);
			case "/settings":
				return setIndex(4);
			case "/settings/wallets":
				return setIndex(4);
			case "/settings/business":
				return setIndex(4);
			case "/developer":
				return setIndex(5);
			case "/developer/webhooks":
				return setIndex(5);
			default:
				setIndex(0);
		}
	}

	useEffect(() => {
		updateIndex(location.pathname);

		return () => {};
	}, [location.pathname]);

	return (
		<LeftDrawer
			variant="permanent"
			open={smaller ? false : open}
			sx={{
				mt: 2,
			}}
		>
			<Box
				sx={{
					backgroundColor: "#16171B",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					height: "100%",
					px: "10px",
				}}
			>
				<Box>
					{/* Logo */}
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							mt: 4,
							mb: 6,
						}}
					>
						<Box
							sx={{
								width: "36px",
								height: "36px",
								mr: 1.5,
							}}
						>
							<img src={Logo} alt="" height="100%" width="100%" />
						</Box>
						<Box sx={{ display: "flex" }}>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "600",
									color: "white",
								}}
							>
								Ziropay
							</p>
							<small style={{ color: "white" }}>&nbsp;beta</small>
						</Box>
					</Box>
					{mainList.map(({ text, i, ai, path }, ind) => (
						<Box
							key={text}
							onClick={() => navigate(path)}
							sx={{
								backgroundColor: index === ind ? "#F4F4F4" : "",
								borderRadius: "8px",
								p: 1,
								py: 1.5,
								mb: 0.4,
								cursor: "pointer",
								"&:hover": {
									background: index === ind ? "" : "rgb(200 200 200 / 35%)",
								},
							}}
						>
							<Box
								sx={{
									justifyContent: open ? "initial" : "center",
									color: index === ind ? "black " : "#828488",
									alignItems: "center",
									display: "flex",
								}}
							>
								<Box
									sx={{
										mr: 2,
										fontSize: "20px",
										display: "flex",
									}}
								>
									{index === ind ? ai() : i()}
								</Box>
								<Box
									sx={{
										opacity: open ? 1 : 0,
										fontWeight: "600",
										fontSize: "14px",
										color: index === ind ? "black " : "white",
									}}
								>
									{text}
								</Box>
							</Box>
						</Box>
					))}
				</Box>

				{/* Down */}
				<Box
					sx={{
						background: `url("${BGImage}")`,
						backgroundColor: "rgba(255, 255, 255, 1)",
						p: 2,
						borderRadius: "4px",
						mb: "18px",
						textAlign: "center",
					}}
				>
					<Box
						mb={2}
						sx={{
							borderRadius: "6px",
							boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.1)",
							backgroundColor: "white",
							p: 1,
							width: "40px",
							height: "40px",
						}}
					>
						<Box
							sx={{
								background: `url("${LogoDark}")`,
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
								backgroundSize: "contain",
								width: "25px",
								height: "25px",
							}}
						></Box>
					</Box>
					<Box mb={2} sx={{ textAlign: "start" }}>
						<p style={{ fontWeight: "600", fontSize: "22px" }}>Need help?</p>
						<p style={{ fontWeight: "600", fontSize: "14px" }}>
							Reach us through socials
						</p>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<SiDiscord
							size={24}
							style={{ margin: "0 16px", cursor: "pointer" }}
							onClick={() => window.open("https://x.com/leostelon", "_blank")}
						/>
						<BiLogoTelegram
							size={24}
							style={{ margin: "0 16px", cursor: "pointer" }}
							onClick={() => window.open("t.me/ziropay_io", "_blank")}
						/>
						<SiTwitter
							size={24}
							style={{ margin: "0 16px", cursor: "pointer" }}
							onClick={() => window.open("https://x.com/ziropay_io", "_blank")}
						/>
					</Box>
				</Box>
			</Box>
		</LeftDrawer>
	);
}
