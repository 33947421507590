import { default as axios } from "axios";
import { SERVER_URL } from "../constant";

export const createPayment = async function (payment_link, token_id, chain_id) {
	try {
		const path = chain_id === 100001 ? "/payment/tron" : "/payment";
		const response = await axios.post(SERVER_URL + path, {
			payment_link,
			token_id,
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const getPayments = async function (id) {
	try {
		const token = localStorage.getItem("token");
		const response = await axios.get(SERVER_URL + `/payment`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const getPayment = async function (id) {
	try {
		const response = await axios.get(SERVER_URL + `/payment/${id}`, {
			headers: {
				"Content-Type": `application/json`,
			},
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const cancelPayment = async function (uid) {
	try {
		const response = await axios.patch(SERVER_URL + `/payment/${uid}`, {
			headers: {
				"Content-Type": `application/json`,
			},
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};
