import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Create } from "./screens/Create";
import { PaymentLinks } from "./screens/PaymentLinks";

import { Completed } from "./screens/Completed";
import { Signin } from "./screens/Signin";
import { Token } from "./screens/Token";
import { Payments } from "./screens/Payments";
import Dashboard from "./screens/Dashboard";
import { Settings } from "./screens/Settings/Settings";
import { Business } from "./screens/Settings/Business";
import { Payment } from "./screens/Payment";
import { Wallets } from "./screens/Settings/Wallet";
import { Developer } from "./screens/Developer/Developer";
import { Webhooks } from "./screens/Developer/Webhooks";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" exact element={<Dashboard />} />
				<Route path="/signin" exact element={<Signin />} />
				<Route path="/signin/:token" exact element={<Token />} />
				<Route path="/create" exact element={<Create />} />
				<Route path="/payments" exact element={<Payments />} />
				<Route path="/payment_links" exact element={<PaymentLinks />} />
				<Route path="/payment/:id" exact element={<Payment />} />
				<Route path="/completed" exact element={<Completed />} />
				<Route path="/settings" exact element={<Settings />} />
				<Route path="/settings/business" exact element={<Business />} />
				<Route path="/settings/wallets" exact element={<Wallets />} />
				<Route path="/developer" exact element={<Developer />} />
				<Route path="/developer/webhooks" exact element={<Webhooks />} />
			</Routes>
		</Router>
	);
}

export default App;
