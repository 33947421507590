import { Box, Grid } from "@mui/material";
import React from "react";
import NaveBar from "../../components/NaveBar";
import { Drawer } from "../../components/Drawer";
import { useNavigate } from "react-router-dom";
import Business from "../../assets/business-building.png";
import Wallet from "../../assets/wallet.png";

export const Settings = () => {
	const navigate = useNavigate();
	const actions = [
		{
			title: "Wallets",
			description: "Add wallets",
			image: Wallet,
			path: "/settings/wallets",
		},
		{
			title: "Business",
			description: "Add business details",
			image: Business,
			path: "/settings/business",
		},
		// {
		// 	title: "Settings",
		// 	description: "Add settings details",
		// 	image: Business,
		// 	path: "/settings/business",
		// },
		// {
		// 	title: "Settings",
		// 	description: "Add settings details",
		// 	image: Business,
		// 	path: "/settings/business",
		// },
		// {
		// 	title: "Settings",
		// 	description: "Add settings details",
		// 	image: Business,
		// 	path: "/settings/business",
		// },
		// { title: "Lilypad Dashboard", image: Lilypad, path: "/lilypad" },
		// { title: "Deploy Docker", image: DockerLogo, path: "/docker" },
		// { title: "Upload File", image: FileUpload, path: "/fileupload" },
	];

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer />
			<Box style={{ width: `calc(100vw - 280px)` }}>
				<NaveBar />
				<Box style={{ width: `calc(100vw - 280px)` }}>
					<Box
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							color: "#4c4848",
						}}
					>
						<Box>
							<h1>Settings</h1>
							<br />
						</Box>
						<Box>
							<h5>General</h5>
							<br />
						</Box>
						<Grid container spacing={{ xs: 2, md: 3 }}>
							{actions.map((i, ind) => (
								<Grid item xs={12} sm={4} md={4} lg={3} key={ind}>
									<Box
										key={ind}
										sx={{
											borderRadius: "12px",
											p: 1,
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
											height: "75px",
											mx: 1,
											ml: ind === 0 ? 0 : 1,
											cursor: "pointer",
											border: `3px solid transparent`,
											backgroundColor: "#e9e9e95d",
											"&:hover": {
												backgroundColor: "#e9e9e99d",
												border: `3px solid #4954FD`,
											},
										}}
										onClick={() => navigate(i.path)}
									>
										<Box>
											<img src={i.image} alt={i.title} height={"50px"} />
										</Box>
										<Box>
											<p
												style={{
													fontWeight: "600",
												}}
											>
												{i.title}
											</p>
											<small>{i.description}</small>
										</Box>
									</Box>
								</Grid>
							))}
						</Grid>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
