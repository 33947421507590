import { default as axios } from "axios";
import { SERVER_URL } from "../constant";

export const getWallets = async function () {
	try {
		const token = localStorage.getItem("token");
		const response = await axios.get(`${SERVER_URL}/wallet`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const createWallet = async function (chain_id, address) {
	const token = localStorage.getItem("token");
	try {
		const response = await axios.post(
			`${SERVER_URL}/wallet`,
			{ chain_id, address },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const deleteWallet = async function (id) {
	const token = localStorage.getItem("token");
	try {
		const response = await axios.delete(`${SERVER_URL}/wallet/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};
