import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Analytics } from "@vercel/analytics/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
			<Analytics />
			<App />
			<ToastContainer
				position="bottom-center"
				pauseOnHover
				hideProgressBar
				autoClose={3000}
			/>
		</GoogleOAuthProvider>
	</>
);
