import { default as axios } from "axios";
import { SERVER_URL } from "../constant";
import { authResolver } from "../utils/authResolver";

export const getPieData = async function (id) {
	try {
		const token = localStorage.getItem("token");
		const response = await authResolver(
			axios.get(SERVER_URL + `/analytics/pie-data`, {
				headers: {
					"Content-Type": `application/json`,
					Authorization: `Bearer ` + token,
				},
			})
		);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};

// Get total payments count based on the status.
export const getStatusCountData = async function (id) {
	try {
		const token = localStorage.getItem("token");
		const response = await authResolver(
			axios.get(SERVER_URL + `/analytics/payment-category`, {
				headers: {
					"Content-Type": `application/json`,
					Authorization: `Bearer ` + token,
				},
			})
		);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};
