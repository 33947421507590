import { Box } from "@mui/material";
import NaveBar from "../components/NaveBar";
import InfoWigets from "../features/dashboard/InfoWigets";
import GraphWidget from "../features/dashboard/GraphWidget";
import { useEffect, useState } from "react";
import { getPayments } from "../api/payment";
import { Drawer } from "../components/Drawer";
import { getUser } from "../api/user";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Dashboard() {
	const [paymentData, setPaymentData] = useState([]);
	const [paymentLoading, setPaymentLoading] = useState(true);
	const navigate = useNavigate();

	async function gP() {
		try {
			setPaymentLoading(true);
			const res = await getPayments();
			if (res.length > 0) setPaymentData(res);
			setPaymentLoading(false);
		} catch (err) {
			console.log(err.message);
		}
	}

	async function gU() {
		const user = await getUser();
		if (user?.status !== 200) {
			navigate("/signin", { replace: true });
			toast("Session has expired!", { type: "error" });
		} else {
			gP();
		}
	}

	useEffect(() => {
		gU();
	}, []);

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer />
			<Box style={{ width: `calc(100vw - 280px)` }}>
				<NaveBar title="Dashboard" />
				<Box
					sx={{
						display: "flex",
					}}
				>
					<Box
						sx={{
							flex: 1,
							p: 3,
						}}
					>
						<InfoWigets paymentData={paymentData} />
						<GraphWidget paymentData={paymentData} loading={paymentLoading} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
