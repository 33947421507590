import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as Dollar } from "../../svg/Dollar.svg";
import { ReactComponent as Checkmark } from "../../svg/checkmark-circle.svg";
import { ReactComponent as Clock } from "../../svg/alarm-clock.svg";
import { ReactComponent as CrossCircle } from "../../svg/cross-circle.svg";
import { getStatusCountData } from "../../api/analytics";
import { PrimaryGrey } from "../../constant";

const wigets = [
	{
		title: "Turnover",
		icon: () => <Dollar />,
		_id: "COMPLETED",
		subId: "volume_total",
	},
	{
		title: "Successful Payments",
		icon: () => <Checkmark />,
		_id: "COMPLETED",
		subId: "total",
	},
	{
		title: "Pending Payments",
		icon: () => <Clock />,
		_id: "PENDING",
		subId: "total",
	},
	{
		title: "Failed Payments",
		icon: () => <CrossCircle />,
		_id: "CANCELED",
		subId: "total",
	},
];

export default function InfoWigets() {
	const [data, setData] = useState();
	useEffect(() => {
		getStatusCountData().then((response) => {
			if (response?.length > 0) {
				let _data = {};
				response.forEach((d) => {
					_data = { ..._data, [d._id]: d };
				});
				console.log(_data);
				setData(_data);
			}
		});
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				flexWrap: "wrap",
			}}
		>
			{wigets.map((w, i) => (
				<Box
					key={i}
					sx={{
						border: "1px solid #EEEEEE",
						borderRadius: "16px",
						minWidth: "274px",
						flex: 1,
						height: "100px",
						display: "flex",
						alignItems: "center",
						ml: i === 0 ? 0 : 3,
						p: 2,
					}}
				>
					<Box
						sx={{
							background: "#d7d7d7",
							width: "68px",
							height: "68px",
							borderRadius: "8px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{w.icon()}
					</Box>

					<Box
						sx={{
							ml: 2,
						}}
					>
						<Box
							sx={{
								fontSize: "16px",
								fontWeight: "regular",
								color: "rgba(10, 11, 34, 0.7)",
							}}
						>
							{w.title}
						</Box>
						<Box
							sx={{
								fontFamily: "Inter",
								fontSize: "20px",
								fontWeight: 700,
								mt: 1.25,
								color: PrimaryGrey,
							}}
						>
							<span
								style={{
									color: w.subId === "volume_total" ? "rgb(91, 199, 23)" : "",
								}}
							>
								{w.subId === "volume_total" ? "$" : ""}
								{data?.[w._id]?.[w.subId] || "-"}
							</span>
						</Box>
					</Box>
				</Box>
			))}
		</Box>
	);
}
