export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const PrimaryGrey = "#828488";

export const ChainsConfig = {
	1: {
		chainId: 1,
		chainName: "Ethereum",
		nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
		rpcUrls: ["https://mainnet.infura.io/v3/"],
		blockExplorerUrls: ["https://etherscan.io"],
	},
	11155111: {
		chainId: 11155111,
		chainName: "Sepolia",
		nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
		rpcUrls: ["https://sepolia.infura.io/v3/"],
		blockExplorerUrls: ["https://sepolia.etherscan.io"],
	},
	137: {
		chainId: 137,
		chainName: "Polygon",
		nativeCurrency: { name: "Polygon", symbol: "MATIC", decimals: 18 },
		rpcUrls: ["https://polygon-rpc.com/"],
		blockExplorerUrls: ["https://polygonscan.com/"],
	},
	80001: {
		chainId: 80001,
		chainName: "Polygon Mumbai",
		nativeCurrency: {
			name: "MATIC",
			symbol: "MATIC",
			decimals: 18,
		},
		rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
		blockExplorerUrls: ["https://mumbai.polygonscan.com"],
	},
};

export const primaryBlack = "#202020";
export const primaryColor = primaryBlack;
export const primaryWhite = "#fafafa";
