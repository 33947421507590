import React, { useEffect, useState } from "react";
import Avatar from "boring-avatars";
import { MdOutlineLogout } from "react-icons/md";
import { AppBar, Box, Avatar as Ava } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../constant";
import { getUser } from "../api/user";
import { googleLogout } from "@react-oauth/google";

export default function NaveBar({ title }) {
	return (
		<Box sx={{ flexGrow: 1, px: 3 }}>
			<AppBar
				position="static"
				sx={{
					bgcolor: "white",
					boxShadow: "none",
					borderBottom: "1px solid #e8e8e8",
				}}
			>
				<Box
					sx={{
						height: "80px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box>
						<h3 style={{ color: "black" }}>{title}</h3>
					</Box>
					<UserBox />
				</Box>
			</AppBar>
		</Box>
	);
}

const UserBox = () => {
	const [user, setUser] = useState({});
	const navigate = useNavigate();

	function logout() {
		localStorage.clear();
		googleLogout();
		navigate("/signin", { replace: true });
	}

	useEffect(() => {
		getUser().then((res) => {
			setUser(res.data);
		});
	}, []);

	return Object.keys(user).length === 0 ? (
		""
	) : (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
			}}
		>
			<Avatar
				size={40}
				name="Maria Mitchell"
				variant="marble"
				colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
			/>
			<Box
				sx={{
					ml: "10px",
				}}
			>
				<Box
					sx={{
						fontSize: "12px",
						color: "grey",
					}}
				>
					My Organization
				</Box>
				<Box
					sx={{
						fontSize: "14px",
						fontWeight: "500",
						color: primaryColor,
						mt: "2px",
						cursor: "pointer",
					}}
					onClick={() => navigate("/settings/business")}
				>
					{user.business ? (
						user.business.name
					) : (
						<p
							style={{
								textDecoration: "underline",
							}}
						>
							Create Business
						</p>
					)}
				</Box>
			</Box>
			{/* Divider */}
			<Box sx={{ flexDirection: "column" }}>
				<span
					style={{
						display: "inline-block",
						color: "white",
						margin: "0 10px",
						height: "2em",
						border: "0.5px solid lightgrey",
					}}
				/>
			</Box>
			<Box mr={3}>
				<Box
					sx={{
						fontSize: "12px",
						color: "grey",
					}}
				>
					Last Login
				</Box>
				<Box
					sx={{
						fontSize: "14px",
						color: primaryColor,
						mt: "2px",
					}}
				>
					{new Date(user.updatedAt).toDateString()}
				</Box>
			</Box>
			<Box
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
			>
				<Ava
					sx={{
						height: "30px",
						width: "30px",
						cursor: "pointer",
					}}
					onClick={logout}
				>
					<MdOutlineLogout color={"#515151"} />
				</Ava>
			</Box>
		</Box>
	);
};
