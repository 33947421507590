import { default as axios } from "axios";
import { SERVER_URL } from "../constant";
import { toast } from "react-toastify";

export const updateBusiness = async function (data) {
	const token = localStorage.getItem("token");

	try {
		const response = await axios.post(SERVER_URL + `/business`, data, {
			headers: {
				"content-type": "multipart/form-data",
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		toast(error.message, { type: "error" });
	}
};

export const getBusiness = async function () {
	const token = localStorage.getItem("token");

	try {
		const response = await axios.get(SERVER_URL + `/business`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
	}
};
