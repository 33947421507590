import { default as axios } from "axios";
import { toast } from "react-toastify";
import { SERVER_URL } from "../constant";

export async function getNetworks() {
	try {
		const response = await axios.get(`${SERVER_URL}/network`);
		return response.data;
	} catch (error) {
		toast("unable to fetch networks", { type: "error" });
	}
}
