import React, { useState } from "react";
import { MdContentCopy, MdLink } from "react-icons/md";
import { PrimaryGrey } from "../constant";
import { Box, Tooltip } from "@mui/material";
import { shortText } from "../utils/shortText";

export const PaymentLinkTile = ({ payment }) => {
	const [copyEnabled, setCopyEnabled] = useState(false);
	const [open, setOpen] = useState(false);

	function toggleCopy() {
		setCopyEnabled(!copyEnabled);
	}

	return (
		<tr>
			<td>{shortText(payment._id)}</td>
			<td>{payment.payment_config.title}</td>
			<td>{payment.amount} USD</td>
			<td
				style={{ fontWeight: "500", color: "#303031", display: "flex" }}
				onMouseEnter={toggleCopy}
				onMouseLeave={toggleCopy}
			>
				{shortText(`${process.env.REACT_APP_BASE_URL}/payment/${payment._id}`)}
				<Box
					onClick={() => {
						window.open(
							`${process.env.REACT_APP_BASE_URL}/payment/${payment._id}`,
							"_blank"
						);
					}}
					mx={1}
				>
					<MdLink
						style={{
							color: PrimaryGrey,
							visibility: copyEnabled ? "visible" : "hidden",
							cursor: "pointer",
						}}
						size={16}
					/>
				</Box>
				<Tooltip
					title="Copied!"
					placement="top"
					open={open}
					onClose={() => setOpen(false)}
				>
					<Box
						onClick={() => {
							navigator.clipboard.writeText(
								`${process.env.REACT_APP_BASE_URL}/payment/${payment._id}`
							);
							setOpen(true);
						}}
					>
						<MdContentCopy
							style={{
								color: PrimaryGrey,
								visibility: copyEnabled ? "visible" : "hidden",
								cursor: "pointer",
							}}
							size={16}
						/>
					</Box>
				</Tooltip>
			</td>
			{/* <td>09/04/2023 20:29</td> */}
			<td>{new Date(payment.createdAt).toDateString()}</td>
		</tr>
	);
};
