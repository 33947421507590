import { Box } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import React, { useEffect, useState } from "react";
import { getPieData } from "../../api/analytics";
import { getNetworks } from "../../api/network";

export const Piechart = () => {
	const [pieData, setPieData] = useState([]);

	// function getColor(chain_id) {
	// 	switch (chain_id) {
	// 		case 1:
	// 			return "#FF5BEF";
	// 		case 11155111:
	// 			return "#FF5BEF";
	// 		case 137:
	// 			return "#5BC4FF";
	// 		case 80001:
	// 			return "#5BC4FF";
	// 		default:
	// 			return "#FF5BEF";
	// 	}
	// }

	async function gPD() {
		const res = await getPieData();
		let data = [];
		if (res.length === 0) {
			const networks = await getNetworks();
			data = networks.map((d) => {
				return {
					id: d._id,
					value: 1,
					label: d.name,
				};
			});
		} else
			data = res.map((d) => {
				return {
					id: d._id,
					value: d.total,
					label: d.network.name,
				};
			});
		setPieData(data);
	}

	useEffect(() => {
		gPD();
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				height: "100%",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<PieChart
				series={[
					{
						data: pieData,
						innerRadius: 65,
						outerRadius: 100,
						cornerRadius: 5,
						paddingAngle: 5,
					},
				]}
				width={450}
				height={350}
			/>
		</Box>
	);
};
