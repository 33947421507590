import React, { useState } from "react";
import { MdContentCopy, MdOutlineLink } from "react-icons/md";
import { PrimaryGrey } from "../constant";
import { Box, Tooltip } from "@mui/material";
import { shortText } from "../utils/shortText";

export const PaymentTile = ({ payment }) => {
	const [copyEnabled, setCopyEnabled] = useState(false);
	const [open, setOpen] = useState(false);

	function toggleCopy() {
		setCopyEnabled(!copyEnabled);
	}

	return (
		<tr>
			<td>{shortText(payment._id)}</td>
			<td>
				<Box
					style={{
						color: "#5bc717",
						fontWeight: "600",
						alignItems: "center",
						display: "flex",
					}}
				>
					<img src={payment.token.logo} alt={payment.uid} height={"20px"} />
					<span style={{ marginLeft: "4px" }}>
						+{payment.total_recieved} {payment.token.token_symbol}
					</span>
				</Box>
			</td>
			<td>
				<small
					style={{
						backgroundColor: statusChips[payment.status].bgcolor,
						width: "min-content",
						padding: "2px 4px",
						color: statusChips[payment.status].color,
						borderRadius: "6px",
					}}
				>
					{payment.status}
				</small>
			</td>
			<td>{payment.payment_link.amount} USD</td>

			<td
				style={{
					fontWeight: "500",
					color: "#303031",
					display: "flex",
					cursor: "pointer",
				}}
				onMouseEnter={toggleCopy}
				onMouseLeave={toggleCopy}
			>
				<MdOutlineLink
					style={{
						color: PrimaryGrey,
					}}
					size={16}
				/>
				<p
					onClick={() => {
						window.open(
							`${process.env.REACT_APP_BASE_URL}/payment/${payment.payment_link._id}`,
							"_blank"
						);
					}}
				>
					{shortText(
						`${process.env.REACT_APP_BASE_URL}/payment/${payment.payment_link._id}`
					)}
				</p>
				<Tooltip
					title="Copied!"
					placement="top"
					open={open}
					onClose={() => setOpen(false)}
				>
					<Box
						onClick={() => {
							navigator.clipboard.writeText(
								`${process.env.BASE_URL}/payment/${payment.payment_link._id}`
							);
							setOpen(true);
						}}
					>
						<MdContentCopy
							style={{
								color: PrimaryGrey,
								visibility: copyEnabled ? "visible" : "hidden",
								cursor: "pointer",
							}}
							size={16}
						/>
					</Box>
				</Tooltip>
			</td>
			<td>{shortText(payment.uid)}</td>
			{/* <td>09/04/2023 20:29</td> */}
			<td>{new Date(payment.createdAt).toDateString()}</td>
		</tr>
	);
};

const statusChips = {
	CREATED: {
		bgcolor: "rgba(117, 117, 117, 0.16)",
		color: "rgba(117, 117, 117, 1)",
	},
	PENDING: {
		bgcolor: "#ffebb7",
		color: "rgba(206, 148, 0, 1)",
	},
	COMPLETED: {
		bgcolor: "rgb(205 250 218)",
		color: "rgb(12, 108, 41)",
	},
	INCOMPLETED: {
		bgcolor: "rgba(10, 43, 107, 0.16)",
		color: "rgba(10, 43, 107, 1)",
	},
	TIMEOUT: {
		bgcolor: "#ffd5da",
		color: "rgba(203, 100, 3, 1)",
	},
	CANCELED: {
		bgcolor: "#ffd5da",
		color: "rgba(203, 100, 3, 1)",
	},
	REFUNDED: {
		bgcolor: "rgba(119, 2, 122, 0.08)",
		color: "rgba(119, 2, 122, 1)",
	},
};
