import "../../styles/PaymentTable.css";
import * as React from "react";
import { Box, Skeleton } from "@mui/material";
import { PaymentTile } from "../../components/PaymentTile";

export default function BaseTable({ paymentData: rows, loading }) {
	return loading ? (
		<Box>
			{Array.from({ length: 10 }).map((_, i) => (
				<Skeleton
					variant="rectangular"
					sx={{ my: 1 }}
					height={"75px"}
					key={i}
				/>
			))}
		</Box>
	) : (
		<Box mt={2}>
			<table>
				<thead>
					<tr>
						<th>Id</th>
						<th>Recieved Amount</th>
						<th>Status</th>
						<th>Requested Amount</th>
						<th>Payment Link</th>
						<th>UID</th>
						<th>Created</th>
					</tr>
				</thead>
				<tbody>
					{rows.slice(0, 4).map((item, i) => (
						<PaymentTile payment={item} key={i} />
					))}
				</tbody>
			</table>
		</Box>
	);
}
