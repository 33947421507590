import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	InputBase,
	Paper,
	Skeleton,
	Slide,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Drawer } from "../../components/Drawer";
import NaveBar from "../../components/NaveBar";
import { BlueButton } from "../../components/BlueButton";
import { PrimaryGrey, primaryBlack } from "../../constant";
import { createWallet, deleteWallet, getWallets } from "../../api/wallet";
import { shortText } from "../../utils/shortText";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const Wallets = () => {
	const [wallets, setWallets] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [defaultWalletAddress, setDefaultWalletAddress] = useState("");

	const handleClose = async () => {
		setOpen(false);
	};

	async function gW() {
		setLoading(true);
		const response = await getWallets();
		setWallets(response);
		setLoading(false);
	}

	async function addAllWallets(chain_id, value) {
		try {
			let totalWallets = wallets.filter((n) => n.wallets.length > 0);
			if (totalWallets.length === 0) {
				if (chain_id === 100001) {
					await createWallet(chain_id, value);
				} else {
					wallets.forEach(async (w) => {
						if (w.chain_id !== 100001) await createWallet(w.chain_id, value);
					});
					setDefaultWalletAddress(value);
					setOpen(true);
				}
			} else {
				await createWallet(chain_id, value);
			}
			toast("Wallet added.", { type: "success" });
		} catch (error) {
			toast(error.message, { type: "error" });
		}
	}

	useEffect(() => {
		gW();
	}, []);

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer />
			<Box style={{ width: `calc(100vw - 280px)` }}>
				<NaveBar />
				<Box style={{ width: `calc(100vw - 280px)` }}>
					<Box
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							color: "#4c4848",
						}}
					>
						<Box>
							<h1>Wallets</h1>
							<br />
						</Box>
						{loading ? (
							<Box>
								{Array.from({ length: 10 }).map((_, i) => (
									<Skeleton
										variant="rectangular"
										sx={{ my: 1 }}
										height={"75px"}
										key={i}
									/>
								))}
							</Box>
						) : (
							<Box mt={2}>
								{wallets.map((n, i) => {
									return (
										<WalletInput
											network={n}
											getWallets={gW}
											key={i}
											addAllWallets={addAllWallets}
										/>
									);
								})}
							</Box>
						)}

						<Dialog
							open={open}
							TransitionComponent={Transition}
							keepMounted
							onClose={handleClose}
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogTitle>{"Default EVM address"}</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-slide-description">
									Added{" "}
									<span style={{ fontWeight: "bold" }}>
										{shortText(defaultWalletAddress)}
									</span>{" "}
									as default EVM address to all chains.
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose}>Continue</Button>
							</DialogActions>
						</Dialog>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

function WalletInput({ network, getWallets, addAllWallets }) {
	const [value, setValue] = useState("");
	const [wallet, setWallet] = useState();

	useEffect(() => {
		if (network.wallets.length !== 0) {
			setWallet(network.wallets[0]);
			setValue(network.wallets[0].address);
		}
	}, [network]);

	return (
		<Paper
			sx={{
				display: "flex",
				mb: 1,
				maxWidth: "650px",
				alignItems: "center",
				justifyContent: "center",
				p: 1,
			}}
		>
			<Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box mr={1}>
						<img src={network.logo} alt={network._id} height={"25px"} />
					</Box>
					{network.name}
				</Box>
			</Box>
			<InputBase
				sx={{
					ml: 1,
					flex: 1,
					width: "100%",
					fontSize: "12px",
					fontWeight: "600",
					color: PrimaryGrey,
				}}
				placeholder="Enter address"
				inputProps={{
					"aria-label": "Enter address",
				}}
				value={value}
				// defaultValue={wallet ? "" : network.wallets[0].address}
				onChange={(e) => setValue(e.target.value)}
			/>
			<Box>
				<BlueButton
					title={wallet ? "Delete" : "Save"}
					style={{
						backgroundColor: primaryBlack,
					}}
					onClick={async () => {
						if (wallet) {
							const confirmed = window.confirm("Delete this address?");
							if (confirmed) await deleteWallet(wallet._id);
						} else {
							await addAllWallets(network.chain_id, value);
						}
						getWallets();
					}}
				/>
			</Box>
		</Paper>
	);
}
