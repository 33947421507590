import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Drawer } from "../components/Drawer";
import { getPayments } from "../api/payment_link";
import { PaymentLinkTile } from "../components/PaymentLinkTile";
import NaveBar from "../components/NaveBar";

export const PaymentLinks = () => {
	const [loading, setLoading] = useState(false);
	const [payments, setPayments] = useState([]);

	async function gP() {
		setLoading(true);
		const jobResolved = await getPayments();
		setPayments(jobResolved);
		setLoading(false);
	}

	useEffect(() => {
		gP();
	}, []);

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer />
			<Box style={{ width: `calc(100vw - 280px)` }}>
				<NaveBar />
				<Box sx={{ display: "flex" }}>
					<Box style={{ width: `calc(100vw - 280px)` }}>
						<Box
							sx={{
								p: 2,
								display: "flex",
								flexDirection: "column",
								color: "#4c4848",
							}}
						>
							<Box>
								<h1>Payment Links</h1>
								<br />
							</Box>
							{loading ? (
								<Box>
									{Array.from({ length: 10 }).map((_, i) => (
										<Skeleton
											variant="rectangular"
											sx={{ my: 1 }}
											height={"75px"}
											key={i}
										/>
									))}
								</Box>
							) : (
								<Box mt={2}>
									<table>
										<thead>
											<tr>
												<th>Id</th>
												<th>Title</th>
												<th>Amount</th>
												<th>Payment Link</th>
												<th>Created</th>
											</tr>
										</thead>
										<tbody>
											{payments.map((item, i) => (
												<PaymentLinkTile payment={item} key={i} />
											))}
										</tbody>
									</table>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
