import {
	Alert,
	Box,
	CircularProgress,
	Divider,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUser, signin, signinGoogle } from "../api/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../constant";
import SignBackground from "../assets/signin-bg.png";
import LogoDark from "../assets/logo-dark.png";
import { useGoogleLogin } from "@react-oauth/google";

export const Signin = () => {
	const navigate = useNavigate();
	async function gU() {
		const user = await getUser();
		if (user?.status === 200) {
			navigate("/", { replace: true });
		}
	}

	useEffect(() => {
		gU();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box display={"flex"} height="100vh" position={"relative"}>
			<LeftComponent />
			<Box
				sx={{
					flex: 1,
					backgroundImage: `url("${SignBackground}")`,
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}
			/>
		</Box>
	);
};

const LeftComponent = () => {
	const [visible, setVisible] = useState(false);

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				height: "100%",
				flex: 1,
			}}
		>
			<LogoWithName />
			<Box>
				<Box
					sx={{
						width: "360px",
						mt: 4.3,
					}}
				>
					<LoginDescription />
					<EmailBox setVisible={setVisible} />
					<SuccessAlert visible={visible} />
				</Box>
				<Footer />
			</Box>
		</Box>
	);
};

const LogoWithName = () => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					width: "36px",
					height: "36px",
					mr: 1.5,
				}}
			>
				<img src={LogoDark} alt="logo" height="100%" width="100%" />
			</Box>
			<Box
				sx={{
					fontFamily: "Inter",
					fontSize: "24px",
					fontWeight: "600",
				}}
			>
				Ziropay
			</Box>
		</Box>
	);
};

const LoginDescription = () => {
	return (
		<Box>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "24px",
					fontWeight: "600",
				}}
			>
				Log in
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "16px",
					fontWeight: "400",
					lineHeight: "24px",
					mt: 1.5,
				}}
			>
				Hello power user, please connect your wallet or signin.
			</Typography>
		</Box>
	);
};

const EmailBox = ({ setVisible }) => {
	const [email, setEmail] = useState("");

	return (
		<Box
			sx={{
				mt: 4,
			}}
		>
			Email
			<EmailInput email={email} setEmail={setEmail} />
			<SigninButton email={email} setEmail={setEmail} setVisible={setVisible} />
			<SingnUpText />
			<Box my={2} sx={{ display: "flex", alignItems: "center" }}>
				<Divider sx={{ flex: 1 }} />
				<Box mx={2}>Or</Box>
				<Divider sx={{ flex: 1 }} />
			</Box>
			<SignInWithGoogleButton />
		</Box>
	);
};

const EmailInput = ({ email, setEmail }) => {
	return (
		<TextField
			placeholder="Enter your email"
			size="small"
			sx={{
				width: "100%",
				mt: 0.75,
			}}
			InputProps={{
				style: {
					border: "1px solid white",
					borderRadius: "8px",
				},
			}}
			value={email}
			onChange={(e) => {
				setEmail(e.target.value);
			}}
		/>
	);
};
const SignInWithGoogleButton = () => {
	const login = useGoogleLogin({
		onSuccess: (res) => {
			signinGoogle(res.code);
		},
		onError: (error) => {
			console.log(error);
		},
		flow: "auth-code",
	});

	return (
		<Box
			sx={{
				width: "360px",
				height: "44px",
				padding: 1.5,
				borderRadius: "8px",

				border: "1px solid rgba(128,128,128,0.5)",
				mt: 2.5,

				display: "flex",
				alignItems: "center",
				justifyContent: "center",

				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={() => login()}
		>
			<img src="googleIcons/google.png" alt="" height="24px" width="24px" />
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "16px",
					fontWeight: 600,
					ml: 1.5,
				}}
			>
				Sign in with Google
			</Typography>
		</Box>
	);
};

const SigninButton = ({ email, setEmail, setVisible }) => {
	const [loading, setLoading] = useState(false);

	async function sI() {
		setLoading(true);
		const status = await signin(email);
		if (status === 201) {
			setEmail("");
			setVisible(true);
			setTimeout(() => {
				setVisible(false);
			}, 5000);
		}
		setLoading(false);
	}
	return (
		<Box
			fontFamily={"'Alata', sans-serif"}
			fontSize={"30px"}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",

				p: "10px 18px",
				mt: 2.5,
				borderRadius: "8px",
				height: "44px",

				background: primaryColor,
				color: "white",

				fontFamily: "Inter",
				fontSize: "16px",
				fontWeight: 600,

				"&:hover": {
					cursor: "pointer",
					background: "black",
				},
			}}
			onClick={() => {
				loading ? toast("Loading", { type: "info" }) : sI();
			}}
		>
			{loading ? (
				<CircularProgress size={14} sx={{ color: "white" }} />
			) : (
				"Signin"
			)}
		</Box>
	);
};

const SingnUpText = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				mt: 2,
			}}
		>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "14px",
					fontWeight: 400,
					mr: 0.5,
				}}
			>
				Login link will be mailed to your{" "}
				<span style={{ textDecoration: "underline" }}> email.</span>
			</Typography>
		</Box>
	);
};

const SuccessAlert = ({ visible }) => {
	return (
		<Alert
			icon={false}
			severity="success"
			variant="outlined"
			sx={{
				fontSize: "16px",
				weight: "500",

				bgcolor: "rgba(58, 151, 76, 0.08)",
				color: "#3A974C",

				mt: 3,
				borderRadius: "8px",
				display: visible ? "block" : "none",
			}}
		>
			Login link has been sent to your Email entered. 🚀
		</Alert>
	);
};

const Footer = () => {
	return (
		<Box
			sx={{
				padding: "32px",
				position: "absolute",
				bottom: 0,
				left: 0,
			}}
		>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "14px",
					fontWeight: 400,
				}}
			>
				©Ziropay
			</Typography>
		</Box>
	);
};
