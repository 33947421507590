import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment/moment";

const data = ({ amount, time }) => ({
	series: [
		{
			name: "series1",
			data: amount,
		},
	],
	options: {
		chart: {
			height: 350,
			type: "area",
			zoom: false,
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			type: "date",
			categories: time,
		},
		tooltip: {
			x: {
				format: "dd/MM/yy, HH:mm",
			},
		},
	},
});

export default function PaymentChart({ paymentData }) {
	const [transactions, setTransactions] = useState({
		amount: [],
		time: [],
	});

	useEffect(() => {
		if (paymentData.length > 0) {
			const amount = paymentData.map((r) =>
				r.payment_link?.amount ? parseFloat(r.payment_link.amount) : 0
			);
			const time = paymentData.map((r) =>
				moment(r.createdAt).toDate().toLocaleString("en-GB", {
					timeZone: "UTC",
					day: "2-digit",
					month: "2-digit",
					// year: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
				})
			);

			setTransactions({ amount, time });
		}
	}, [paymentData]);

	return (
		<div>
			<div id="chart">
				<ReactApexChart
					options={data(transactions).options}
					series={data(transactions).series}
					height={350}
				/>
			</div>
			<div id="html-dist"></div>
		</div>
	);
}
