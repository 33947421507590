import { Box, Checkbox, Fade, FormControlLabel } from "@mui/material";
import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { createPayment } from "../api/payment_link";
import { Drawer } from "../components/Drawer";
import NaveBar from "../components/NaveBar";

import { InputFieldComponent } from "../components/InputField";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { primaryBlack } from "../constant";
import { BlueButton } from "../components/BlueButton";
import { useNavigate } from "react-router-dom";
import { getWallets } from "../api/wallet";
import { toast } from "react-toastify";

const LocalContext = createContext();
const initData = {
	isFixedAmount: true,
	redirectEnabled: false,
};

const noVlaueChecker = (value) => {
	return value === undefined || value === null || value === "";
};

export const Create = () => {
	const navigate = useNavigate();
	const wallets = useRef([]);
	const [walletAlert, setWalletAlert] = useState(false);
	const [advancedChecked, setAdvancedChecked] = useState(false);
	const [paymentChecked, setPaymentChecked] = useState(true);
	const [data, setData] = useState(initData);
	const [requiredError, setRequiredError] = useState({
		title: false,
		amount: false,
	});
	const [loading, setLoading] = useState(false);

	const handleChange = (key, value) => {
		let _requiredError = { ...requiredError };
		let _data = { ...data, [key]: value };

		if (_requiredError[key])
			_requiredError = { ..._requiredError, [key]: false };

		[
			{ id: "isFixedAmount", check: "amount" },
			{ id: "redirectEnabled", check: "redirect_url" },
		].forEach((f) => {
			if (key === f.id && value) {
				_requiredError = { ..._requiredError, [f.check]: false };
			}
			if (
				key === f.id &&
				!value &&
				_requiredError?.[f.check] !== undefined &&
				_requiredError?.[f.check] !== null
			) {
				delete _requiredError[f.check];
				delete _data[f.check];
			}
		});

		if (value === "") delete _data[key];

		setRequiredError(_requiredError);
		setData(_data);
	};

	const cp = async () => {
		try {
			if (walletAlert)
				return toast("Please add atleast one wallet to recieve payments", {
					type: "info",
				});
			if (loading) return;
			let errorList = {};

			for (const key of Object.keys(requiredError))
				if (noVlaueChecker(data[key]))
					errorList = { ...errorList, [key]: true };

			if (Object.keys(errorList).length > 0) {
				setRequiredError((e) => ({ ...e, ...errorList }));
				return;
			}
			setLoading(true);

			await createPayment(data.amount || 0, data);
			setData(initData);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		getWallets().then((res) => {
			wallets.current = res;
			let totalWallets = wallets.current.filter((n) => n.wallets.length > 0);
			if (totalWallets.length === 0) {
				setWalletAlert(true);
			}
		});
	}, [walletAlert]);

	return (
		<LocalContext.Provider value={{ data, handleChange, requiredError }}>
			<Box sx={{ display: "flex" }}>
				<Drawer />
				<Box style={{ width: `calc(100vw - 280px)` }}>
					<NaveBar />
					<Box style={{ width: `calc(100vw - 280px)` }}>
						<Box
							sx={{
								p: 2,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								color: "#4c4848",
							}}
						>
							<Box>
								<h1>Create Payment</h1>
								<br />
							</Box>
							<Box mt={2} maxWidth={"420px"}>
								{walletAlert ? (
									<Box
										mb={2}
										sx={{
											backgroundColor: "#ffa8a87b",
											p: 1.5,
											borderRadius: 2,
											color: "#ff2424",
											fontWeight: "600",
										}}
									>
										<h5>Add Wallet</h5>
										<p
											style={{
												fontSize: "12px",
												marginTop: "4px",
											}}
										>
											Payment links can be created once the wallet has been
											added.&nbsp;
											<span
												style={{
													textDecoration: "underline",
													cursor: "pointer",
												}}
												onClick={() => navigate("/settings/wallets")}
											>
												Click here to add your wallets.
											</span>
										</p>
									</Box>
								) : (
									""
								)}
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										fontWeight: "500",
										border: "1px solid #f3f3f3",
										borderRadius: paymentChecked ? "0" : "4px",
										p: 1.5,
										background: paymentChecked ? "#f3f3f3" : "inherit",
										"&:hover": {
											cursor: "pointer",
											background: "#f3f3f3",
										},
									}}
									onClick={() => setPaymentChecked((prev) => !prev)}
								>
									{paymentChecked ? (
										<MdKeyboardArrowDown />
									) : (
										<MdKeyboardArrowRight />
									)}
									<p>Payment</p>
								</Box>
								<Fade in={paymentChecked}>
									<Box
										sx={{
											p: 1.5,
											border: "1px solid #f3f3f3",
											display: paymentChecked ? "block" : "none",
										}}
									>
										<Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
											<FormControlLabel
												control={
													<Checkbox
														checked={data.isFixedAmount}
														size="small"
														onChange={(e) =>
															handleChange("isFixedAmount", e.target.checked)
														}
													/>
												}
												sx={{
													mr: "0px",
												}}
											/>
											<Box>
												<h5>Fixed Amount</h5>
											</Box>
										</Box>
										<Box sx={{ display: "flex" }}>
											<FormControlLabel
												control={
													<Checkbox
														checked={!data.isFixedAmount}
														size="small"
														onChange={(e) =>
															handleChange("isFixedAmount", !data.isFixedAmount)
														}
													/>
												}
												sx={{
													mr: "0px",
												}}
											/>
											<Box>
												<h5>Custom Amount</h5>
												<small>Customer chooses how much to pay</small>
											</Box>
										</Box>
										{data.isFixedAmount ? (
											<InputField
												title={"Amount"}
												placeholder={"Enter amount"}
												name="amount"
												sx={{ mb: 2, mt: 1 }}
											/>
										) : (
											""
										)}
										<InputField
											title={"Title"}
											placeholder={"Enter title"}
											name="title"
											sx={{ mb: 2 }}
										/>
										<InputField
											title={"Description"}
											placeholder={"Enter description"}
											name="description"
											sx={{ mb: 2 }}
										/>
									</Box>
								</Fade>

								{/* Advanced Settings */}
								<Box sx={{ my: 2 }}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											fontWeight: "500",
											border: "1px solid #f3f3f3",
											borderRadius: advancedChecked ? "0" : "4px",
											p: 1.5,
											background: advancedChecked ? "#f3f3f3" : "inherit",
											"&:hover": {
												cursor: "pointer",
												background: "#f3f3f3",
											},
										}}
										onClick={() => setAdvancedChecked((prev) => !prev)}
									>
										{advancedChecked ? (
											<MdKeyboardArrowDown />
										) : (
											<MdKeyboardArrowRight />
										)}
										<p>Advance settings</p>
									</Box>
									<Fade in={advancedChecked}>
										<Box
											sx={{
												p: 1.5,
												border: "1px solid #f3f3f3",
												display: advancedChecked ? "block" : "none",
											}}
										>
											<InputField
												title={"Button label"}
												placeholder={'Default\'s to "Pay"'}
												name="action_button_title"
												sx={{ mb: 2, mt: 1 }}
											/>
											<Box sx={{ display: "flex", mb: 2 }}>
												<FormControlLabel
													control={
														<Checkbox
															size="small"
															onChange={(e) =>
																handleChange(
																	"redirectEnabled",
																	e.target.checked
																)
															}
														/>
													}
													sx={{
														mr: "0px",
													}}
												/>
												<Box>
													<h5>Redirection</h5>
													<small>
														Redirect customer's after succesful payments
													</small>
												</Box>
											</Box>
											{data.redirectEnabled ? (
												<InputField
													title={"Redirect URL"}
													placeholder={"Enter redirection url"}
													name="redirect_url"
												/>
											) : (
												""
											)}
										</Box>
									</Fade>
								</Box>

								{/* Action Button */}

								<BlueButton
									title={"Create Payment"}
									onClick={cp}
									style={{
										width: "100%",
										background: primaryBlack,
										mb: 2,
									}}
								/>

								{/* Footer */}
								<Box
									sx={{
										backgroundColor: "#d6d6d67b",
										p: 1.5,
										borderRadius: 2,
										color: "#858585",
										fontSize: "12px",
										fontWeight: "600",
									}}
								>
									*Payments can be created and shared through links to collect
									money.
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</LocalContext.Provider>
	);
};

function InputField({ title, placeholder, name, sx }) {
	const { data, handleChange, requiredError } = useContext(LocalContext);
	return (
		<Box
			sx={{
				...sx,
			}}
		>
			{title}
			<InputFieldComponent
				placeholder={placeholder}
				name={name}
				value={data[name] || ""}
				error={requiredError?.[name] || false}
				onChange={(e) => {
					handleChange(e.target.name, e.target.value);
				}}
			/>
		</Box>
	);
}
