import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../api/user";
import { toast } from "react-toastify";

export const Token = () => {
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const { token } = useParams();

	async function gU() {
		const user = await getUser();
		if (user.status === 200) {
			navigate("/", { replace: true });
		} else {
			toast("Invalid token or token has expired!", { type: "error" });
		}
		setLoading(false);
	}

	useEffect(() => {
		localStorage.setItem("token", token);
		gU();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	return (
		<Box
			sx={{
				height: "100vh",
				width: "100vw",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			{loading ? "Loading..." : "Invalid token"}
		</Box>
	);
};
