import { Box, TextField } from "@mui/material";
import React from "react";

export const InputFieldComponent = ({
	title,
	placeholder,
	value,
	onChange,
	name,
	error = false,
}) => {
	return (
		<Box>
			{title}
			<TextField
				placeholder={placeholder}
				size="small"
				sx={{
					width: "100%",
					mt: 0.75,
				}}
				InputProps={{
					style: {
						border: "1px solid white",
						borderRadius: "8px",
					},
				}}
				error={error}
				value={value}
				onChange={onChange}
				name={name}
			/>
		</Box>
	);
};
