export async function authResolver(promise) {
	try {
		const resp = await promise;
		return resp;
	} catch (error) {
		if (error.response && error.response.status === 401) {
			window.location.replace("/signin");
		}
	}
}
