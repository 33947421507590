import { Box } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { InputFieldComponent } from "../../components/InputField";
import { getBusiness, updateBusiness } from "../../api/business";
import { toast } from "react-toastify";
import NaveBar from "../../components/NaveBar";
import { Drawer } from "../../components/Drawer";
import { BlueButton } from "../../components/BlueButton";
import { primaryBlack } from "../../constant";
import { IoMdAddCircle } from "react-icons/io";
import Logo from "../../assets/logo.png";

const LocalContext = createContext();
const initData = {
	name: "",
	address: "",
	email: "",
	phone: "",
	website: "",
	logo: "",
	brand_color: "",
};

export const Business = () => {
	const [data, setData] = useState(initData);
	const [image, setImage] = useState(null);

	const handleChange = (key, vlaue) => {
		setData((s) => ({ ...s, [key]: vlaue }));
	};

	function handleLogoChange(e) {
		if (e.target.files.length >= 1) {
			setData((s) => ({ ...s, logo: e.target.files[0] }));
			setImage(URL.createObjectURL(e.target.files[0]));
		}
	}

	const handleUpdate = async () => {
		let formData = new FormData();
		const k = Object.keys(data);
		for (var i = 0; i < k.length; i++) {
			const v = data[k[i]];
			if (v && v !== "") {
				formData.append(k[i], v);
			}
		}
		const res = await updateBusiness(formData);
		if (res) {
			toast("Business updated successfully", { type: "success" });
			setData(res);
		}
	};

	const getData = async () => {
		const res = await getBusiness();
		if (res) {
			setData(res);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer />
			<Box style={{ width: `calc(100vw - 280px)` }}>
				<NaveBar />
				<Box style={{ width: `calc(100vw - 280px)` }}>
					<Box
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							color: "#4c4848",
						}}
					>
						<Box>
							<h1>Business Details</h1>
							<br />
						</Box>
						<Box mt={2}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<label htmlFor="logo-input">
									<Box
										sx={{
											cursor: "pointer",
											mb: 2,
											fontWeight: "600",
											height: "110px",
											width: "110px",
											borderRadius: "50%",
											position: "relative",
											background: `url("${
												image ? image : data.logo ? data.logo : Logo
											}")`,
											backgroundRepeat: "no-repeat",
											backgroundPosition: "center",
											backgroundColor: "lightgrey",
											backgroundSize: "cover",
											filter: "gray",
										}}
									>
										<input
											type="file"
											name="logo-input"
											id="logo-input"
											style={{ display: "none" }}
											onChange={handleLogoChange}
											accept="image/png, image/gif, image/jpeg"
										/>
										<Box
											sx={{
												position: "absolute",
												bottom: 0,
												right: 0,
											}}
										>
											<IoMdAddCircle size={"24px"} color="grey" />
										</Box>
									</Box>
								</label>
							</Box>
							<LocalContext.Provider value={{ data, handleChange }}>
								<InputField title="Business name" name="name" />
								<InputField title="Address" name="address" />
								<InputField title="Email" name="email" />
								<InputField title="Phone" name="phone" />
								<InputField title="Website" name="website" />
								<InputField title="Brand color" name="brand_color" />

								<BlueButton
									title={"Update"}
									onClick={handleUpdate}
									style={{
										width: "100%",
										background: primaryBlack,
										mt: 4,
									}}
								/>
							</LocalContext.Provider>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

function InputField({ title, placeholder, name, sx = {} }) {
	const { data, handleChange } = useContext(LocalContext);
	return (
		<Box
			sx={{
				mb: 1.5,
				width: "100%",
				minWidth: "300px",
				...sx,
			}}
		>
			{title}
			<InputFieldComponent
				placeholder={placeholder ? placeholder : `Enter ${title}`}
				name={name}
				value={data[name]}
				onChange={(e) => {
					handleChange(e.target.name, e.target.value);
				}}
			/>
		</Box>
	);
}
